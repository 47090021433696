import React from 'react';
import ReactDOM from 'react-dom/client';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import Login from './components/auths/login';
import Signup from './components/auths/signup';
import { MyContextProvider } from './appContexts';


function AppWithProvider() {
  return (
    <MyContextProvider>
      <App />
    </MyContextProvider>
  );
}

if (process.env.NODE_ENV === 'development') {
  localStorage.setItem('base_url', 'http://127.0.0.1:8000');
} else {
  localStorage.setItem('base_url', window.location.origin);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/*" element={<AppWithProvider />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
      </Routes>
    </Router>
  </React.StrictMode>
);

