import React, { useState, useEffect } from 'react';
import FreeTextQuestion from './freetextQestion';
import MultiChices from './multiChices';

export default function Onboard() {
    const [questionset, setQuestionset] = useState({});


    useEffect(() => {
        const access = localStorage.getItem('access');
        const base_url = localStorage.getItem('base_url');
        fetch(`${base_url}/api/get-questionset/`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${access}`
            },
        }).then(response => {

            if (response.status === 200) {
                return response.json();
            }
            else {
                return null
            }
        }).then(data => {
            console.log(data);
            setQuestionset(data)
        })
    }, [])



    return (
        <div className='w-full h-full flex flex-col justify-center items-center p-4 sm:p-12 md:px-24 relative'>

            {questionset ?
                questionset.type === 'freetext' ? <FreeTextQuestion data={questionset} setQuestionset={setQuestionset} /> : <MultiChices data={questionset} setQuestionset={setQuestionset} />
                :
                <p>Loading.........</p>
            }

        </div>
    )
}                           