import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useAppContext } from "../../appContexts";

export default function Sidenav(props) {
    const { User } = useAppContext();
    const [chatlist, setChatlist] = useState([]);

    function logout() {
        localStorage.removeItem('access');
        localStorage.removeItem('refresh');
        window.location.href = '/';
    }

    useEffect(() => {
        function getChatList() {
            try {
                const access = localStorage.getItem('access');
                const base_url = localStorage.getItem('base_url');

                fetch(`${base_url}/api/get-chat-list/`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${access}`
                    },
                }).then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                }).then(data => {
                    setChatlist(data);
                })
            } catch {
                setChatlist([]);
            }

        }

        getChatList();

        let interval = setInterval(getChatList, 5000);
        return () => clearInterval(interval);
    }, []);

    const navelm = chatlist?.map(chat => {
        return (
            <NavLink to={`/assistant/chat/${chat.slug}`} className="p-2 py-1 rounded hover:bg-neutral-800 w-full flex flex-row justify-between items-center cursor-pointer group" onClick={() => props.toggle(false)}>
                <p className="text-sm truncate">{chat.title}</p>
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="h-8 w-8 p-1 opacity-0 group-hover:opacity-100 transition-all" viewBox="0 0 448 512"><path d="M8 256a56 56 0 1 1 112 0A56 56 0 1 1 8 256zm160 0a56 56 0 1 1 112 0 56 56 0 1 1 -112 0zm216-56a56 56 0 1 1 0 112 56 56 0 1 1 0-112z" /></svg>
            </NavLink>
        )
    })

    return (
        <div className="w-full h-full flex flex-col text-gray-200">
            <div className="p-2 flex flex-row">
                <NavLink to="/assistant/" className="p-2 rounded-md hover:bg-neutral-800 w-full flex">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="h-6 w-6" viewBox="0 0 448 512"><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" /></svg>
                    <p className="text-md text-left pl-4">New Chat</p>
                </NavLink>
            </div>

            <div className="mt-3 flex flex-col p-2 h-full w-full container overflow-auto">
                {navelm}
            </div>

            <div className="mt-1 flex flex-col p-2 mb-1 w-full flex-col items-center justify-end">
                {(User ?
                    <button onClick={() => logout()} className="p-2 rounded bg-neutral-800 w-full flex justify-between items-center cursor-pointer group">
                        <p className="text-md font-bold truncate text-center w-full">Logout</p>
                    </button>
                    :
                    <NavLink to="/login" className="p-2 rounded bg-neutral-800 w-full flex justify-between items-center cursor-pointer group">
                        <p className="text-md font-bold truncate text-center w-full">Login</p>
                    </NavLink>
                )}
            </div>

        </div>
    )
}