import React, { useState, useEffect } from "react";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'

export default function FreeTextQuestion(props) {
    const data = props.data
    const [anser, setAnser] = useState('');
    const {
        transcript,
        listening,        
    } = useSpeechRecognition();

    useEffect(() => {
        if (!listening) {
            setAnser(anser+' '+transcript);
        }
    }, [transcript, listening]);


    function give_anser() {
        const access = localStorage.getItem('access');
        const base_url = localStorage.getItem('base_url');

        let form = new FormData();
        form.append('message', anser);

        fetch(`${base_url}/api/give-answer/${data.id}/`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${access}`
            },
            body: form,
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            }
            else {
                return null
            }
        }).then(data => {
            console.log(data);

            props.setQuestionset(data)
        })

    }


    return (
        <div className='w-full md:w-4/5 xl:w-9/12 h-full flex flex-col justify-center items-center p-4 relative bg-slate-900 rounded-lg shadow text-center'>


            <p className='text-lg'>{data.question}</p>


            <div className="w-64 my-2 mt-4 flex flex-col shadow-lg rounded-lg">
                <textarea className="border border-gray-100 border-b-0 focus:outline-none outline-none w-full p-2 px-4 text-sm resize-none bg-transparent rounded-t-lg" placeholder="Answer" rows="2" value={anser} onChange={(e) => setAnser(e.target.value)}></textarea>

                <div className="flex items-center p-1 w-full justify-between bg-transparent border border-gray-200 rounded-b-lg">
                    <div className="flex items-center">
                        <svg onClick={listening ? SpeechRecognition.stopListening : SpeechRecognition.startListening} xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-5 h-5 shadow-lg drop-shadow-lg rounded-full mx-2" viewBox="0 0 384 512"><path d="M192 0C139 0 96 43 96 96V256c0 53 43 96 96 96s96-43 96-96V96c0-53-43-96-96-96zM64 216c0-13.3-10.7-24-24-24s-24 10.7-24 24v40c0 89.1 66.2 162.7 152 174.4V464H120c-13.3 0-24 10.7-24 24s10.7 24 24 24h72 72c13.3 0 24-10.7 24-24s-10.7-24-24-24H216V430.4c85.8-11.7 152-85.3 152-174.4V216c0-13.3-10.7-24-24-24s-24 10.7-24 24v40c0 70.7-57.3 128-128 128s-128-57.3-128-128V216z" /></svg>

                        {listening ? <p>Listening</p> : null}
                    </div>

                    <button className="mx-2 px-2" onClick={() => give_anser()} >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-5 h-5 shadow-lg drop-shadow-lg rounded-full" viewBox="0 0 512 512"><path d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z" /></svg>
                    </button>
                </div>
            </div>

        </div>
    )
}