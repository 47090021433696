import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Routes, Route } from "react-router-dom";
import NewChat from "./newchat";
import ChatElement from "./chatElement"; 

export default function MainChat(props) {
    
    return (
        <div className="w-full md:w-10/12 p-4 h-full flex flex-col relative z-0">
            <button className="absolute top-4 left-4 rounded-md bg-transparent text-white md:hidden focus:outline-none focus:shadow-outline-purple z-20" onClick={() => props.setShowSidenave(true)}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-6 h-6" viewBox="0 0 448 512"><path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"/></svg>
            </button>
            <Routes>
                <Route path="/*" element={<NewChat />} />
                <Route path="/chat/:slug" element={<ChatElement />} />
            </Routes>
        </div>
    )
}