import React, { useState } from "react";

export default function MultiChices(props) {
    const data = props.data
    const choices = data.choices


    function give_anser(choiced){
        const access = localStorage.getItem('access');
        const base_url = localStorage.getItem('base_url');

        let form = new FormData();
        form.append('choice', choiced);

        fetch(`${base_url}/api/give-answer/${data.id}/`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${access}`
            },
            body: form,
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            }
            else {
                return null
            }
        }).then(data => {
            console.log(data);
            props.setQuestionset(data)
        })

    }

    const ans= choices?.map((ch) => {
        return(
            <button className="mx-2 p-2 border border-slate-700 focus:outline-none outline-none w-full rounded text-sm" onClick={() => give_anser(ch.id)}>{ch.choice}</button>
        )
    })

    return (
        <div className='w-full md:w-4/5 xl:w-9/12 h-full flex flex-col justify-center items-center p-4 relative bg-slate-900 rounded-lg shadow text-center'>


            <p className='text-lg'>{data.question}</p>


            <div className="w-64 my-2 mt-4 flex flex-row shadow-lg rounded-lg">


                {ans}
                
            </div>

        </div>
    )
}