import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Onboard from './components/onboard/onboard';
import Assistant from './Assistant';

const App = () => {


  return (
    <Routes>
      <Route path="/" element={<Onboard />} />
      <Route path="/assistant/*" element={<Assistant />} />

    </Routes>
  );
};
export default App;