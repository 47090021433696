// MyContext.js
import { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


const AppContext = createContext();

export const MyContextProvider = ({ children }) => {
  const [User, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {

    function getUser() {
      const refresh = localStorage.getItem('refresh');
      const base_url = localStorage.getItem('base_url');

      try {
        fetch(`${base_url}/auths/token/refresh/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ refresh: refresh }),

        }).then(response => {
          if (response.ok) {
            return response.json();
          }
        }).then(data => {
          if (data) {
            localStorage.setItem('access', data.access);
          }
        }).catch()
      } catch { }

      fetch(`${base_url}/auths/user-info/`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access')}`
        }
      }).then(response => response.json()).then(data => {
        if (data.username) {
          setUser(data);
        } else {
          try {
            fetch(`${base_url}/auths/token/refresh/`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ refresh: refresh }),
    
            }).then(response => {
              if (response.ok) {
                return response.json();
              } else {
                navigate('/login');
              }
            }).then(data => {
              if (data) {
                localStorage.setItem('access', data.access);
                window.location.reload();
              } else {
                navigate('/login');
              }
            }).catch()
          } catch { }
          
        }
      }).catch()
    }


    const interval = setInterval(() => {
      getUser();
    }, 10000);
    getUser();
    return () => clearInterval(interval);
  }, [navigate])

  return (
    <AppContext.Provider value={{ User }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppContext);
};
