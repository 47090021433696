import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

export default function ChatElement() {
    const { slug } = useParams();
    const [prompt, setPrompt] = useState('');
    const [messages, setMessages] = useState([]);
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
            messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
        };


    useEffect(() => {
        const access = localStorage.getItem('access');
        const base_url = localStorage.getItem('base_url');
        fetch(`${base_url}/api/get-chats/${slug}/`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${access}`
            },
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            if (data) {
                setMessages(data.questions);
                setTimeout(() => {
                    scrollToBottom()
                }, 500);
            }
        })

    }, [slug])

    function ask_question() {
        const access = localStorage.getItem('access');
        const base_url = localStorage.getItem('base_url');
        let form = new FormData();
        form.append('question', prompt);
        
        setMessages([...messages, { question: prompt, answer: null }])
        setPrompt('');
        setTimeout(() => {
            scrollToBottom()
        }, 500);

        fetch(`${base_url}/api/ask-question/${slug}/`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${access}`
            },
            body: form,
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            if (data) {
                // remove lements if answer is null
                let newList=messages?.map( (message) => {
                    if (message.answer !== null) {
                        return message
                    }
                    return null
                })

                setMessages([...newList, { question: prompt, answer: data.answer }])
                setTimeout(() => {
                    scrollToBottom()
                }, 500);
            }
        })
    }

    const chats = messages.map((message) => {
        return (
            <div className="w-full py-2 flex flex-col text-white">
                <div className="w-full flex flex-col">
                    <p className="text-lg font-bold mb-1">You</p>
                    <p className="text-md mb-2">{message.question}</p>

                </div>
                {message.answer ?
                    <div className="w-full flex flex-col">
                        <p className="text-lg font-bold mb-1">System</p>
                        <p className="text-md mb-2">{message.answer}</p>
                    </div>
                    : 
                    <div className="w-full flex flex-col">
                        <p className="text-lg font-bold mb-1">System</p>
                        <p className="text-md mb-10">Loading...</p>
                    </div>}
            </div>
        )
    })

    return (
        <div className="w-full h-full flex flex-col relative z-0">
            <div className="w-full h-16 mb-4">

            </div>

            <div className="w-full h-full container overflow-y-auto">
                {chats ? chats : null}
                <div ref={messagesEndRef}></div>
            </div>
            <div className="w-full p-4 md:px-8">
                <div className="relative z-0 w-full text-white">
                    <input onChange={(e) => setPrompt(e.target.value)} value={prompt} onKeyDown={(e) => e.key === 'Enter' ? ask_question() : null} className="w-full p-2 py-4 bg-transparent border border-neutral-700 focus:border-neutral-500 outline-none rounded-xl text-gray-300" placeholder="Type your message here..." />
                    <svg onClick={() => ask_question()} xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="absolute top-4 right-4 h-6 w-6" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM385 231c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-71-71V376c0 13.3-10.7 24-24 24s-24-10.7-24-24V193.9l-71 71c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9L239 119c9.4-9.4 24.6-9.4 33.9 0L385 231z" /></svg>
                </div>
            </div>
        </div>
    )
}