import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

export default function Signup() {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('')

    const navigate = useNavigate();

    const handleSubmit = (e) => {
        const base_url = localStorage.getItem('base_url');
        let form = new FormData();
        form.append('username', username);
        form.append('email', email);
        form.append('password', password);
        fetch(`${base_url}/auths/signup/`, {
            method: 'POST',
            body: form,
        }).then(response => response.json()).then(data => {
            if (data.success) {
                localStorage.setItem('access', data.access);
                localStorage.setItem('refresh', data.refresh);
                window.location.href = '/';
            } else {
                setError(data.error)
            }
        })
    }
    return (
        <div>
            <div className="text-center mt-24">

                <h2 className="text-xl tracking-tight font-extrabold text-white sm:text-3xl md:text-4xl">
                    Create your account
                </h2>
                <span className="text-sm text-gray-400">or <NavLink to="/login" className="text-blue-500">
                    Already have an account
                </NavLink>
                </span>
            </div>
            <div className="flex justify-center my-2 mx-4 md:mx-0">
                <form className="w-full max-w-xl bg-neutral-900 rounded-lg shadow-lg p-6 text-white">
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3 mb-6">
                            <label className="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-2">User name</label>
                            <input onChange={(e) => setUsername(e.target.value)} className="w-full bg-transparent text-white autofill:bg-transparent font-medium border-2 border-stone-700 shadow shadow-inner shadow-stone-700 rounded-lg py-3 px-3 leading-tight focus:outline-none" placeholder='User name' required></input>
                        </div>
                        <div className="w-full px-3 mb-6">
                            <label className="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-2">Email</label>
                            <input onChange={(e) => setEmail(e.target.value)} className="w-full bg-transparent text-white autofill:bg-transparent font-medium border-2 border-stone-700 shadow shadow-inner shadow-stone-700 rounded-lg py-3 px-3 leading-tight focus:outline-none" placeholder='Email' required></input>
                        </div>
                        <div className="w-full px-3 mb-6">
                            <label className="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-2" >Password</label>
                            <input onChange={(e) => setPassword(e.target.value)} className="w-full bg-transparent text-white autofill:bg-transparent font-medium border-2 border-stone-700 shadow shadow-inner shadow-stone-700 rounded-lg py-3 px-3 leading-tight focus:outline-none" type='password' placeholder='Password' required></input>
                        </div>
                        <div className="w-full flex items-center justify-between px-3 mb-3 ">
                            <label for="remember" className="flex items-center w-1/2">
                                <span className="text-sm text-gray-700 pt-1"> By creating an account you agree to our <NavLink className="text-blue-500">Terms and Conditions</NavLink></span>
                            </label>
                        </div>
                        <div className="w-full md:w-full px-3 mb-3">
                            {error != "" ?
                                <p className='my-1 text-red-400 text-sm text-center'>{error}</p>
                                : ""
                            }
                            <button className="w-full shadow-lg py-3 bg-neutral-800 hover:bg-neutral-700 focus:shadow-outline focus:outline-none rounded-lg text-white" type='button' onClick={handleSubmit}>Sign Up</button>
                        </div>

                    </div>
                </form>
            </div>
        </div>
    )
}