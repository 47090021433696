import React from 'react';
import { useState, useEffect } from 'react';
import Sidenav from './components/chat/sidenav';
import MainChat from './components/chat/mainChat';
import { useAppContext } from './appContexts';
const Assistant = () => {
  const [showSidenave, setShowSidenave] = useState(false);
  const { user } = useAppContext();

  return (
    <div className='flex flex-row h-screen w-screen'>


      {/* side nav */}
      <div className={'z-20 w-64 overflow-y-auto bg-neutral-900 absolute h-screen lg:static top-0 lg:block flex-shrink-0 -translate-x-full lg:translate-x-0 transition-translate duration-200 ease-in border-box hide_scroll_bar ' + (showSidenave ? 'translate-x-0' : '-translate-x-full')} >
        <button className="z-20 absolute top-4 right-0 rounded-md bg-transparent md:hidden focus:outline-none focus:shadow-outline-purple text-white" onClick={() => setShowSidenave(false)}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
          </svg>
        </button>

        <Sidenav toggle={setShowSidenave} />

      </div>

      {/* main */}
      <div className='w-full h-screen lg:container flex flex-col bg-stone-800'>

        <div className='w-full h-full flex flex-col items-center lg:container overflow-y-auto'>
          <MainChat setShowSidenave={setShowSidenave}/>
        </div>

      </div>

    </div>
  );
};
export default Assistant;