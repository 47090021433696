import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

export default function NewChat(props) {
    const navigate = useNavigate();
    const [prompt, setPrompt] = useState('');

    function createNewChat() {
        
        const access = localStorage.getItem('access');
        const base_url = localStorage.getItem('base_url');
        let form = new FormData();
        form.append('prompt', prompt);
        setPrompt('');
        fetch(`${base_url}/api/start-chat/`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${access}`
                },
            body: form,
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
                if (data.success) {
                    navigate(`/chat/${data.slug}`);
                }
            })
    }
    return (
        <div className="w-full h-full flex flex-col relative z-0">
            <div className="w-full h-full container overflow-auto">

            </div>
            <div className="w-full p-4 md:px-8">
                <div className="relative z-0 w-full text-white">
                    <input onChange={(e) => setPrompt(e.target.value)} value={prompt} onKeyDown={(e) => e.key === 'Enter' ? createNewChat() : null} className="w-full p-2 py-4 bg-transparent border border-neutral-700 focus:border-neutral-500 outline-none rounded-xl text-gray-300" placeholder="Type your message here..." />
                    <svg onClick={() => createNewChat()} xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="absolute top-4 right-4 h-6 w-6" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM385 231c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-71-71V376c0 13.3-10.7 24-24 24s-24-10.7-24-24V193.9l-71 71c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9L239 119c9.4-9.4 24.6-9.4 33.9 0L385 231z" /></svg>
                </div>
            </div>
        </div>
    )
}